import React, {useState} from 'react';
import './style.css'
import searchImage from '../../assets/feather-search.svg'
import saveImage from '../../assets/feather-save.svg';
import playImage from '../../assets/feather-play.svg';
import crunchbaseImage from '../../assets/crunchbase.png'
import qScoutLogo from '../../assets/logo.png'


function LeftBar() {
	const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordionIndex(openAccordionIndex === index ? null : index);
  };
	return (
		<div>
			<div className="leftbar">
				<div className="logo">
					<img src={qScoutLogo} alt="" />
				</div>
				<nav>
					<ul>
						<li><a ><em><img src={searchImage} alt="" /></em> <span>Search</span></a></li>
						<li><a ><em><img src={saveImage} alt="" /></em> <span>Starred Startups</span></a></li>
						<li><a ><em><img src={playImage} alt="" /></em> <span>Starred Startups</span></a></li>
					</ul>
				</nav>
				<button className="accordion" onClick={() => toggleAccordion(0)}>
          Segment <span className={`arrow ${openAccordionIndex === 0 ? 'up' : 'down'}`}>&#9660;</span>
        </button>
        <div className={`panel ${openAccordionIndex === 0 ? 'open' : ''}`}>
          <ul className="checkbox-list">
            <li><input type="checkbox" id="item1" /><label htmlFor="item1">Status</label><span>4</span></li>
            <li><input type="checkbox" id="item2" /><label htmlFor="item2">Last funding</label><span>3</span></li>
            <li><input type="checkbox" id="item3" /><label htmlFor="item3">Updated</label><span>7</span></li>
          </ul>
        </div>
        <button className="accordion" onClick={() => toggleAccordion(1)}>
          Segment <span className={`arrow ${openAccordionIndex === 1 ? 'up' : 'down'}`}>&#9660;</span>
        </button>
        <div className={`panel ${openAccordionIndex === 1 ? 'open' : ''}`}>
          <ul className="checkbox-list">
            <li><input type="checkbox" id="item4" style={{marginRight: '5px'}}/><label htmlFor="item4"> Item 4</label></li>
            <li><input type="checkbox" id="item5" style={{marginRight: '5px'}}/><label htmlFor="item5"> Item 5</label></li>
            <li><input type="checkbox" id="item6" style={{marginRight: '5px'}}/><label htmlFor="item6"> Item 6</label></li>
          </ul>
        </div>
				<div className="powered">
					<p>Powered By:</p>
					<figure><img src={crunchbaseImage} alt="" /></figure>
				</div>
			</div>
		</div>
	)
}

export default LeftBar
