import React, { useState } from 'react';
import './style.css'


function StartupCard({ data }) {
  debugger
  return (
    <div className="startupbox">
      {/* <div className="check">
        <input type="checkbox" />
      </div> */}
      <div className="startuptext">
        <div className="logo-details">
        <figure><img src={data.image_url} /> </figure> 
          <div className="strattxt">
            <h2>{data.name}</h2>
            <ul>
              <li><span className="category">Category</span></li>
              <li><strong>Website:</strong> {data.website}</li>
              <li><strong>Funding Stage:</strong>  {data?.funding_stage?.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}</li>
              <li><strong>Total funding:</strong> {data?.funding_total || null}</li>
              <li><strong>Last funding:</strong> {data.last_funding_at || null}</li>
              {/* <li><strong>Address:</strong> {data.address}</li>
              <li><strong>Funding Stage:</strong>  {data.funding_stage.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}</li>
              <li><strong>Total funding:</strong> {data.funding_total}</li> */}

            </ul>
            <ul>
           
           <li><strong>Address:</strong> {data.address}</li>
           <li><strong>Contact:</strong> {data?.contact_email} | {data?.phone_number}</li>

         

         </ul>
          </div>
         
        </div>
        <div className="text-details">
          <p>{data.summary} </p>
        </div>
        <h3 className='heading'> Technologies</h3>
        <div className='technologies'>
          {data.technologies.map((technology, index) => (
            <div className='technology_div' key={index}>{technology}</div>
          ))}
        </div>
        <h3 className='heading'> Clients</h3>
        <div className='technologies'>
          {data.clients.map((client, index) => (
            <div className='technology_div' key={index}>{client}</div>
          ))}
        </div>
        <h3 className='heading'> Keywords</h3>
        <div className='technologies'>
          {data.keywords.map((keyword, index) => (
            <div className='technology_div' key={index}>{keyword}</div>
          ))}
        </div>


      </div>
      {/* <div className="qscore">
        20 <span>Qscore</span>
      </div> */}

    </div>
  )
}

export default StartupCard
