// App.js
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';
import SearchStartup from './components/startupsList/SearchStartup';
import StartupList from './components/startupsList/startupList';

function App() {
  return (
    <Router>
      

        <Routes>
          <Route path="/" element={<StartupList />} />
          <Route path="/search" element={<SearchStartup />} />
        </Routes>
    </Router>
  );
}

export default App;
