import React, { useEffect, useState } from 'react';
import LeftBar from './LeftBar';
import StartupCard from './StartupCard';
import './style.css';
import { getStartupDetails } from '../../apis/getApi';

export default function StartupList() {
  const [searchText, setSearchText] = useState('');
  const [startupData, setStartupData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);

  useEffect(() => {
    async function fetchData() {
      const data = await getStartupDetails({ pagenumber: currentPage });
      setStartupData(data.data);
      setTotalPages(data.totalPages);
    }

    fetchData();
  }, [currentPage]);

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const pageButtons = [];
    const maxPagesToShow = 5;
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, currentPage + 2);

    if (endPage - startPage < maxPagesToShow - 1) {
      if (currentPage <= Math.ceil(maxPagesToShow / 2)) {
        endPage = Math.min(totalPages, maxPagesToShow);
      } else {
        startPage = Math.max(1, totalPages - maxPagesToShow + 1);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          className={currentPage === i ? 'active' : ''}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    return pageButtons;
  };

  return (
    <div className="dashboard">
      {/* <LeftBar /> */}
      <div className="righsection">
        {/* <div className="searchbar">
          <div className="form-group">
            <input
              type="text"
              placeholder="Search Startup"
              onChange={handleInputChange}
            />
            <button>Search</button>
          </div>
        </div> */}
        <div className="startup-section">
          {startupData.map((data, index) => (
            <StartupCard key={index} data={data} />
          ))}
        </div>
        <div className="pagination_div">
          {currentPage > 1 && (
            <button onClick={() => handlePageChange(currentPage - 1)}>
              Previous
            </button>
          )}
          {renderPagination()}
          {currentPage < totalPages && (
            <button onClick={() => handlePageChange(currentPage + 1)}>
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
