import axiosInstance from "./axiosInstance";

export const getStartupDetails = async ({ pagenumber }) => {
  try {
    const response = await axiosInstance.get(`/organization/getlists?page=${pagenumber}&limit=10`);
    
    // Assuming the backend returns data in the response.data field
    const data = response.data;
    
    // Assuming the backend also returns the total count of items
    const totalCount = response.totalCount;
    
    // Calculate the total number of pages based on the total count and items per page
    const pageSize = 10; // Number of elements per page
    const totalPages = Math.ceil(totalCount / pageSize);

    return { data: data.data, totalPages: data.totalPages };
  } catch (error) {
    console.error('Error fetching startup details:', error);
    throw error; // Rethrow the error after logging it
  }
};

